@function material-icons-str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      material-icons-str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

@function material-icons-content($codepoint) {
  @return unquote('"\\#{$codepoint}"');
}

@mixin material-icons-font-face(
  $font-family: $material-icons-font-family,
  $font-name: $material-icons-font-name,
  $otf: false,
  $font-path: $material-icons-font-path,
  $font-display: $material-icons-font-display
) {
  $font-file: $font-path + $font-name;

  @font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-display: $font-display;
    src: url('#{$font-file}.eot'); /* For IE6-8 */
    src: local($font-family), local($font-name),
      url('#{$font-file}.woff2') format('woff2'),
      url('#{$font-file}.woff') format('woff'),
      if(
        $otf,
        url('#{$font-file}.otf') format('opentype'),
        url('#{$font-file}.ttf') format('truetype')
      );
  }
}

@mixin material-icons(
  $font-family: $material-icons-font-family,
  $font-size: $material-icons-font-size
) {
  font-family: $font-family;
  font-weight: normal;
  font-style: normal;
  font-size: $font-size;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

@mixin material-icon($name, $pseudo: 'before') {
  $codepoint: map-get($material-icons-codepoints, $name);

  &:#{$pseudo} {
    content: material-icons-content($codepoint);
  }
}
