@import 'variables';
@import 'mixins';

@include material-icons-font-face();

.material-icons {
  @include material-icons();
}

@each $name in 'Outlined', 'Round', 'Sharp', 'Two Tone' {
  $font-family: 'Material Icons #{$name}';
  $font-name: material-icons-str-replace($name, ' ', '');
  $font-name: 'MaterialIcons#{$font-name}-Regular';
  $class-name: material-icons-str-replace(to-lower-case($name), ' ', '-');

  @include material-icons-font-face($font-family, $font-name, true);

  .material-icons-#{$class-name} {
    @extend .material-icons;
    font-family: $font-family;
  }
}
